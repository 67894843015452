.Intro {
    margin-top: 140px;
}

.Intro .heading {
    text-align: center;
    padding-right: 15%;
    padding-left: 15%;
    margin-bottom: 200px;
}

.Intro .heading h1 {
    margin-bottom: 27px;
    color: #fff;
    font-size: 28px;
    line-height: 53px;
    font-weight: 300;
}

.Intro .heading .button {
    border: 1px solid #6f73ff;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #6f73ff;
    font-size: 13px;
    line-height: 19px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 25px;
    text-decoration: none;
    cursor: pointer;
}

.Intro .heading .button:hover {
    color: #fff;
    border-color: #fff;
}

.Intro h3 {
    margin-top: 0px;
    margin-bottom: 32px;
    color: #fff;
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0px;
    text-transform: none;
    line-height: 30px;
}

.Intro .section-title {
    font-weight: 700;
}

.Intro .more {
    text-align: center;
}

.Intro .more .link {
    color: #fff;
    font-weight: bold;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    font-weight: 700;
}

.Intro .more .link:hover {
    color: #6f73ff;
    letter-spacing: 1px;
}

.Typist {
    font-weight: 500;
}

.Typist .Cursor {
    display: inline-block;
    color: #6f73ff;
}

.Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 480px) {
    .Intro .heading h1 {
        font-size: 34px;
    }
}

@media (min-width: 768px) {
    .Intro .heading h1 {
        font-size: 40px;
    }
}

@media (min-width: 992px) {
    .Intro .heading h1 {
        font-size: 48px;
    }
}

@media (min-width: 566px) and (max-width: 767px) {
    .Intro .Portfolio .Card:nth-child(3) {
        display: none;
    }
}
