.Portfolio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.Portfolio h2 {
    width: 100%;
    font-size: 25px;
    margin: 12px 36px
}

.Portfolio h3 {
    padding: 8px 36px;
    width: 100%;
    font-weight: 400
}

.Portfolio .filters {
    margin: 80px auto 40px auto;
    text-align: center;
}

.Portfolio .filters button {
    outline: none;
}

.Portfolio .filters .filter {
    font-weight: 600;
    border: 1px solid #222;
    background: #222;
    padding: 12px 20px;
    line-height: 1;
    border-radius: 52px;
    margin: 12px 4px 0px 4px;
    color: #fff;
    -webkit-transition: 0.3s all;
    transition: 0.3s all
}

@media (min-width: 473px) {
    .Portfolio .filters {
        width: 90%;
    }
}

@media (min-width: 521px) {
    .Portfolio .filters {
        width: 79%;
    }
}

@media (min-width: 706px) {
    .Portfolio .filters {
        width: 100%;
    }
}

@media (min-width: 780px) {
    .Portfolio .filters .filter {
        margin: 12px 6px 0px 6px;
    }
}

@media (min-width: 880px) {
    .Portfolio .filters .filter {
        margin: 12px 12px 0px 12px;
    }
}

.Portfolio .filters .filter:hover {
    cursor: pointer;
    color: #4676fd;
    background-color: #f7f9ff;
    border: 1px solid #dbe4ff;
}

.Portfolio .filters .filter:hover:nth-child(3n+2) {
    color: #20b376;
    background-color: #f6fffb;
    border: 1px solid #cdf5e4;
}

.Portfolio .filters .filter:hover:nth-child(3n+3) {
    color: #f78f40;
    background-color: #fffaf7;
    border: 1px solid #ffeada;
}

.Portfolio .filters .filter.selected {
    color: #fff;
    background-color: #4676fd;
    border: 1px solid #4676fd;
}

.Portfolio .filters .filter.selected:nth-child(3n+2) {
    background-color: #20b376;
    border: 1px solid #20b376;
}

.Portfolio .filters .filter.selected:nth-child(3n+3) {
    background-color: #f78f40;
    border: 1px solid #f78f40;
}

.Portfolio .filters .filter.selected:hover {
    color: #fff;
}
