.Overlay {
    padding: 100px 8%;
    background-color: rgba(0, 0, 0, 0.60);
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.90)
}

.Overlay button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
}

.Overlay a {
    text-decoration: none;
    color: #6f73ff;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -o-transition: all .1s linear;
    transition: all .1s linear;
    font-weight: 700;
}

.Overlay a:hover {
    text-decoration: underline double;
    color: #6f73ff;
}

.Overlay .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: transparent;
    cursor: pointer;
}

.Overlay .close .lr {
    z-index: 1051;
    width: 1px;
    height: 50px;
    margin-left: 35px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.Overlay .close .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 50px;
    background-color: #fff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.OverlayItem h1 {
    margin-bottom: 2px;
    text-shadow: rgba(0, 0, 0, 0.01) 0px 2px 10px;
}

.OverlayItem .position {
    font-size: 1.3em;
    margin-bottom: 4px;
}

.OverlayItem .description {
    margin-top: 28px;
    text-align: justify;
    text-indent: 30px;
    font-size: 1.4em;
    line-height: 1.6em;
}

.OverlayItem .icon {
    margin-top: 18px;
    text-align: center;
    font-size: 4em;
    line-height: 1.4em;
}

.OverlayItem p b {
    font-weight: 900;
}

.Overlay h1 {
    font-size: 34px;
    font-weight: 900;
}

@media (min-width: 992px) {
    .Overlay h1 {
        font-size: 40px;
    }
}

.Overlay .udemy-logo {
    margin-bottom: 8px;
    width: 180px;
}

.Overlay .fd-logo {
    margin-left: -10px;
    margin-bottom: 8px;
    width: 220px;
}
