.Footer {
    margin: 0px auto;
    text-align: center;
    padding: 80px 0px 40px 0px;
}

.Footer a {
    margin: 40px;
}

.Footer a:hover {
    color: #0076b5;
}

.Footer a:hover:nth-child(3n+2) {
    color: #8466cb;
}

.Footer a:hover:nth-child(3n+3) {
    color: #cc3534;
}
