.Contact {
    margin: 100px 0px;
    text-align: center;
}

.Contact .heading {
    padding-right: 15%;
    padding-left: 15%;
    margin-bottom: 60px;
}

.Contact .heading h1 {
    margin-bottom: 27px;
    color: #fff;
    font-size: 3.6em;
    line-height: 53px;
    font-weight: 700;
}

.Contact .form {
    display: block;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

.Contact .form form {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Contact .field {
    font-family: Mark, sans-serif;
    font-size: 12px;
    font-weight: 300;
    float: left;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.Contact .input {
    min-height: 50px;
    margin-bottom: 19px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-style: none;
    border-radius: 3px;
    background-color: #222222;
    -webkit-transition: box-shadow 200ms ease;
    transition: box-shadow 200ms ease;
    color: #fff;
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
}

.Contact .input:focus {
    outline: none !important;
    border: 1px solid #20b376;
}

.Contact .input:focus:nth-child(3n+2) {
    outline: none !important;
    border: 1px solid #4676fd;
}

.Contact .input:focus:nth-child(3n+3) {
    outline: none !important;
    border: 1px solid #f78f40;
}

.Contact .button {
    border: 1px solid #6f73ff;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: Mark, sans-serif;
    color: #6f73ff;
    font-size: 13px;
    line-height: 19px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 25px;
    text-decoration: none;
    cursor: pointer;
}

.Contact .button:hover {
    color: #fff;
    border-color: #fff;
}

.Contact .button:focus {
    outline: #fff;
}

.Contact .input.text-area {
    height: 150px;
}

@media (min-width: 600px) {
    .Contact .heading h1 {
        font-weight: 500;
    }

    .Contact .form form {
        width: 60%;
    }
}
