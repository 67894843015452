.Navigation {
    padding-top: 28px
}

.Navigation .bg-dark {
    background-color: transparent !important;
}

.navLinkItem {
    margin: 20px 18px;
    transition: color 200ms ease;
    -webkit-transition: color 200ms ease;
    font-family: Mark, sans-serif;
    font-size: 2em;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 3px;
}

.navLinkItem:hover,
.navLinkItem.active {
    color: #4676fd !important;
}

.navLinkItem:hover:nth-child(3n+2),
.navLinkItem.active:nth-child(3n+2) {
    color: #20b376 !important;
}

.navLinkItem:hover:nth-child(3n+3),
.navLinkItem.active:nth-child(3n+3) {
    color: #f78f40 !important;
}

.navbar-dark .navbar-toggler,
.navbar-dark .navbar-toggler:hover {
    border: none;
    background: transparent !important;
}

.navbar-dark .navbar-toggler .top-bar {
    transform: rotate(45deg);
    transform-origin: 40% 40%;
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 40% 40%;
}

.navbar-dark .navbar-toggler .middle-bar {
    opacity: 0;
}  

.navbar-dark .navbar-toggler .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 40% 40%;
    -webkit-transform: rotate(-45deg);
    -webkit-transform-origin: 40% 40%;
}
 
.navbar-dark .icon-bar {
    transition: all 0.2s;
    background-color: #888;
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 1px;
}

.navbar-dark .navbar-toggler.collapsed .icon-bar+.icon-bar {
    margin-top: 6px;
}

.navbar-dark .navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
    -webkit-transform: rotate(0);
}
  
.navbar-dark .navbar-toggler.collapsed .middle-bar {
    opacity: 1;
}
  
.navbar-dark .navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
    -webkit-transform: rotate(0);
}

.navbar-nav {
    margin-top: 20vh;
    margin-bottom: 15%;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .navLinkItem {
        margin: 10px 18px;
        font-size: 0.86em;
        line-height: 17px;
        text-transform: uppercase;
    }

    .navbar-nav {
        margin-top: 4px;
        margin-bottom: 0px;
    }
}
