@font-face {
  font-family: 'Fh_Script';
  font-style: normal;
  font-weight: 400;
  src: 
    local('Fh_Script'),
    url(./fonts/Fh_Script.otf) format('opentype');
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Light.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Heavy.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Extlight.otf) format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Book.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Bold.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(./fonts/MarkOT-Black.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
}

html, body {
  user-select: none;
  background-color: #000;
  font-family: Mark, sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

a {
  text-decoration: none;
  color: #999;
}

a:hover {
  text-decoration: none;
  color: #fff;
}

a.active {
  text-decoration: none;
  color: #6f73ff;
}

button {
  outline: none;
}

button:focus {
  outline: none;
}

.signature {
  font-family: 'Fh_Script', Helvetica;
  font-size: 3em;
}
