
.Card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 268px;
    height: 325px;
    margin: 0 auto 30px auto;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 7px 0px 0px rgba(255, 255, 255, 0);
    box-shadow: 0px 7px 0px 0px rgba(255, 255, 255, 0);
    -webkit-transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
    transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s, -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
    cursor: pointer;
    text-decoration: none;
}

@media (min-width: 566px) {
    .Card {
        width: 42%;
    }
}

@media (min-width: 768px) {
    .Card {
        width: 30%;
    }
}

.Card.animate {
    animation: FadeIn .2s linear;
    animation-fill-mode: both;
}


@keyframes FadeIn { 
  0% {
    opacity: 0;
    /* transform: scale(.01); */
  }

  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

.Card.animate:nth-child(1) { animation-delay: .1s }
.Card.animate:nth-child(2) { animation-delay: .2s }
.Card.animate:nth-child(3) { animation-delay: .3s }
.Card.animate:nth-child(4) { animation-delay: .4s }
.Card.animate:nth-child(5) { animation-delay: .5s }
.Card.animate:nth-child(6) { animation-delay: .6s }
.Card.animate:nth-child(7) { animation-delay: .7s }
.Card.animate:nth-child(8) { animation-delay: .8s }
.Card.animate:nth-child(9) { animation-delay: .9s }
.Card.animate:nth-child(10) { animation-delay: 1s }
.Card.animate:nth-child(11) { animation-delay: 1.1s }
.Card.animate:nth-child(12) { animation-delay: 1.2s }
.Card.animate:nth-child(13) { animation-delay: 1.3s }
.Card.animate:nth-child(14) { animation-delay: 1.4s }
.Card.animate:nth-child(15) { animation-delay: 1.5s }
.Card.animate:nth-child(16) { animation-delay: 1.6s }
.Card.animate:nth-child(17) { animation-delay: 1.7s }
.Card.animate:nth-child(18) { animation-delay: 1.8s }
.Card.animate:nth-child(19) { animation-delay: 1.9s }
.Card.animate:nth-child(20) { animation-delay: 2.0s }
.Card.animate:nth-child(21) { animation-delay: 2.1s }
.Card.animate:nth-child(22) { animation-delay: 2.2s }
.Card.animate:nth-child(23) { animation-delay: 2.3s }
.Card.animate:nth-child(24) { animation-delay: 2.4s }
.Card.animate:nth-child(25) { animation-delay: 2.5s }
.Card.animate:nth-child(26) { animation-delay: 2.6s }
.Card.animate:nth-child(27) { animation-delay: 2.7s }
.Card.animate:nth-child(28) { animation-delay: 2.8s }
.Card.animate:nth-child(29) { animation-delay: 2.9s }
.Card.animate:nth-child(30) { animation-delay: 3.0s }

.Card .imageWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 60px 40px 40px 40px;
    font-size: 3em;
}

.Card .imageWrapper.hasIcon {
    padding: 106px 40px 40px 40px;
}

.Card .imageWrapper img {
    max-width: 100px;
    max-height: 100px;
    -ms-flex-item-align: center;
    align-self: center;
}

.Card .textWrapper {
    display: block;
    padding: 14px 24px 16px 24px;
}

.Card .textWrapper.color {
    background-color: rgba(0, 0, 0, 0.05);
    color: white;
}

.Card .textWrapper.white {
    background-color: rgba(0, 0, 0, 0.08);
    color: black;
}

.Card .textWrapper h4 {
    padding: 0;
    font-size: 1.5rem;
    letter-spacing: 0.01em;
    text-shadow: rgba(0, 0, 0, 0.01) 0px 2px 10px;
}

.Card .textWrapper p {
    line-height: 20px;
    font-size: 1rem;
    opacity: 0.75;
    padding: 0;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
}

.Card .label {
    font-family: Mark, sans-serif;
    font-weight: 600;
    letter-spacing: 0.2px;
    position: absolute;
    right: 17px;
    top: 15px;
    background-color: #fff;
    font-size: 12px;
    border-radius: 2px;
    opacity: 0;
    -webkit-transition: 0.4s all;
    transition: 0.4s all;
    padding: 2px 8px 1px 8px;
    margin: 0;
    z-index: 2
}

.Card:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    -webkit-box-shadow: 0px 2px 37px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 37px 0px rgba(0, 0, 0, 0.2)
}

.Card:hover .label {
    opacity: 1
}

.Card:active {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px)
}
