.About {
    margin: 100px 20px 20px 20px;
    text-align: center;
}

.About .heading {
    margin-bottom: 60px;
}

.About .heading h1 {
    color: #fff;
    font-size: 4em;
    line-height: 53px;
    font-weight: 700;
}

.About p {
    text-align: justify;
    text-indent: 30px;
    font-size: 1.4em;
    line-height: 1.3em;
    line-height: 1.6em;
}

.About a {
    text-decoration: none;
    color: #6f73ff;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -o-transition: all .1s linear;
    transition: all .1s linear;
    font-weight: 700;
}

.About a:hover {
    text-decoration: underline double;
    color: #6f73ff;
}

@media (min-width: 600px) {
    .About .heading h1 {
        font-weight: 500;
    }
}
