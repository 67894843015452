.Content {
    width: 100%;
    height: 100%;
    background: url(/images/shapes-optimized.jpg) repeat repeat fixed; 
    background: url(/images/shapes-optimized.jpg) repeat repeat fixed; 
    -webkit-background-size: 1000px;
    -moz-background-size: 1000px;
    -o-background-size: 1000px;
    background-size: 1000px;
}

.Content .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    min-height: 100vh;
}

@media (min-width: 480px) {
    .Content {
      min-height: 420px;
    }
}

@media (min-width: 768px) {
    .Content {
      min-height: 520px;
    }
}

@media (min-width: 992px) {
    .Content {
        min-height: 540px;
    }
}
